//react
import { useEffect } from "react";
//internal
import Error4xx from "./Error4xx";
import Error5xx from "./Error5xx";

//3rd party
import { Navigate, useParams } from "react-router-dom";
//utils and helpers

export default function ErrorPageHandler(props) {
  useEffect(() => {
    return () => localStorage.setItem("error_p", JSON.stringify(false));
  }, []); //for error pages
  const { error } = useParams();
  if (JSON.parse(localStorage.getItem("error_p"))) {
    if (props.forced404) {
      return <Error4xx status={404} />;
    }
    if (error >= 400 && error < 500) {
      return <Error4xx status={error} />;
    } else if (error >= 500 && error <= 599) {
      return <Error5xx status={error} />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return <>{<Navigate to="/" />}</>;
  }
}
