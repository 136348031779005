//3rd party
import axios from "axios";
import { useSnackbar } from "notistack";
//react
import { useState } from "react";
//hooks
import useAuth from "../hooks/useAuth";
//utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//internal
import SignUpForm from "../components/forms/signupForm";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [, setResponse] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (email, password1, firstName, lastName) => {
    setLoading(true);

    try {
      const config = axiosConfig({
        uri: `/auth/registration/manual/`,
        method: "POST",
        data: {
          email,
          password1,
          first_name: firstName,
          last_name: lastName,
          type: "SUPERVISOR",
        },
      });
      let response = await axios({ ...config });

      setLoading(false);
      setResponse(response.data);
      navigate("/accounts/signin");
      notificationsHandler(
        "success",
        "SignUp Succesfull. Please login with the same credentials.",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      setLoading(false);
    }
  };

  if (auth.authenticated) {
    navigate(urlpatterns.dashboard);
  }

  return (
    <>
      <SignUpForm loading={loading} handleSubmit={handleSubmit} />
    </>
  );
}
