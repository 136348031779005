////////////System Imports//////////////
import React, { useContext, useCallback, useEffect, useRef } from "react";

////////////MUI Imports////////////////
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
// internal
import ComponentLoader from "../loader/ComponentLoader";
//context
import { globalContext } from "../../context/globalContext";
//utils
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//libs
import { useSnackbar } from "notistack";
export default function AvailableCredits(props) {
  const globalCtx = useRef();
  globalCtx.current = useContext(globalContext);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //callback for fetching the sidebar credits
  const getSidebarCredits = useCallback(
    async (abortController = null) => {
      globalCtx.current.setSidebarCredits((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      try {
        const config = axiosConfig({
          uri: `/credits/summary`,
          method: "get",
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController ? abortController.signal : null,
        });
        if ("available" in response.data && response.data.available) {
          globalCtx.current.setSidebarCredits({
            loading: false,
            available: response.data?.available,
            used: response.data?.used,
            error: false,
          });
        } else {
          globalCtx.current.setSidebarCredits((prev) => {
            return {
              ...prev,
              loading: false,
              error: false,
            };
          });
        }
      } catch (error) {
        globalCtx.current.setSidebarCredits((prev) => {
          return {
            ...prev,
            loading: false,
            error: true,
          };
        });
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [globalCtx, enqueueSnackbar, closeSnackbar]
  );

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      getSidebarCredits(abortController);
    })();

    return () => abortController.abort();
  }, [getSidebarCredits]);

  return (
    <Paper
      elevation={0}
      sx={{
        background: "transparent",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          width: "248px",
          background: "rgba(44, 169, 227, 0.25)",
          borderRadius: "4px",
          mx: "auto",
          pb: "24px",
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton
            aria-label="refresh-credits"
            title="Refresh Credits"
            onClick={() => {
              getSidebarCredits();
            }}
            sx={{ color: props.theme === "dark" ? "#000" : "#fff" }}
          >
            <ReplayIcon />
          </IconButton>
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          {globalCtx.current.sidebarCredits?.loading ||
          globalCtx.current.sidebarCredits?.error ? (
            <ComponentLoader
              error={globalCtx.current.sidebarCredits.error}
              loading={globalCtx.current.sidebarCredits.loading}
              transparent={true}
              message={false}
              minHeight="100%"
              theme="light"
              retry={() => {
                getSidebarCredits();
              }}
              sx={{ minHeight: "111px" }}
            />
          ) : (
            <>
              <Typography
                sx={{ fontSize: "28px", fontWeight: "500", color: "#FFFFFF" }}
              >
                {globalCtx.current.sidebarCredits?.available?.toFixed(2)}
              </Typography>
              <Typography
                component="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#FFFFFF",
                  lineHeight: "19px",
                  display: "block",
                  "&&": {
                    mb: "20px",
                  },
                }}
              >
                Total Credits Available
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "19px",
                  color: "#FFFFFF",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "22px",
                    marginRight: "4px",
                    color: "#FFFFFF",
                  }}
                >
                  {globalCtx.current.sidebarCredits?.used?.toFixed(2)}
                </span>
                Credits Used
              </Typography>
            </>
          )}
        </Stack>
      </Box>
    </Paper>
  );
}

AvailableCredits.defaultProps = {
  getSidebarCredits: () => {},
};
