import { useState } from "react";
//3rd party
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
//internal
import ForgotPasswordForm from "../components/forms/forgotPasswordForm";
//hooks
import useAuth from "../hooks/useAuth";
//utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
import axios from "axios";

function ForgotPassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  //axiosInstance

  const handleSubmit = async (email) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/`,
        data: { email },
      });
      await axios({
        ...config,
      });
      notificationsHandler(
        "success",
        "Password reset email has been sent",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    setLoading(false);
  };

  if (auth.authenticated) {
    navigate(urlpatterns.dashboard);
  }
  return <ForgotPasswordForm loading={loading} handleSubmit={handleSubmit} />;
}

export default ForgotPassword;
