// libraries
import { Link } from "react-router-dom";

// material UI styles
import { alpha } from "@mui/material/styles";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

// material UI icons
import DoneIcon from "@mui/icons-material/Done";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

// utils
import urls from "../../utils/urls.json";

export default function Requests({ data }) {
  return (
    <>
      <Grid item xs={12}>
        {/* START: Credit Request */}
        <Paper
          elevation={0}
          sx={{
            height: "100%",
            padding: "4px",
          }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              style={{
                margin: 0,
                padding: "15px 0px 20px 10px",
                color: "#022539",
              }}
            >
              Credits Request
            </Typography>
          </Toolbar>
          <Grid container spacing={5}>
            <Grid item xs={12} style={{ minHeight: "90px" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={3}>
                  <Avatar
                    variant="square"
                    style={{
                      backgroundColor: alpha("#47D7A2", 0.1),
                      marginLeft: "35px",
                      padding: "15px 14px 15px 14px",
                      borderRadius: "7px",
                    }}
                  >
                    <DoneIcon style={{ color: "#47D7A2" }} />
                  </Avatar>
                </Grid>

                <Grid
                  item
                  xs={9}
                  sx={{
                    height: "100%",
                    paddingLeft: "24px",
                  }}
                >
                  <Grid container direction="column" alignItems="start">
                    <Typography
                      variant="h5"
                      align="left"
                      style={{ width: "60%", color: "#666666" }}
                    >
                      <b>{data?.completed || 0} </b>
                    </Typography>
                    <Typography variant="body2" style={{ color: "#666666" }}>
                      Credit requests active
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ minHeight: "90px" }}>
              <Grid container direction="row" alignItems="start">
                <Grid item xs={3}>
                  <Avatar
                    variant="square"
                    style={{
                      backgroundColor: alpha("#FB6281", 0.1),
                      marginLeft: "35px",
                      padding: "5px 5px 5px 5px",
                      borderRadius: "7px",
                    }}
                  >
                    <HourglassEmptyIcon style={{ color: "#FB6281" }} />
                  </Avatar>
                </Grid>

                <Grid
                  item
                  xs={9}
                  sx={{
                    height: "100%",
                    paddingLeft: "24px",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    // style={{ height: "100%" }}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      style={{ color: "#666666" }}
                    >
                      <b> {data?.pending || 0}</b>
                    </Typography>
                    <Typography variant="body2" style={{ color: "#666666" }}>
                      Credit requests inactive
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    padding: "20px 0px",
                  }}
                  component={Link}
                  to={urls.credit_requests}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    style={{
                      height: "42px",
                      width: "245px",
                      backgroundColor: "#2F7EC7",
                      color: "#FFFFFF",
                    }}
                  >
                    Manage Requests
                  </Button>
                  {/* <Typography
                    variant="button"
                    color="secondary"
                    align="center"
                  ></Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* END: Credit Request */}
      </Grid>
    </>
  );
}
