import {
  Stack,
  Pagination,
  Typography,
  PaginationItem,
  IconButton,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { drawerWidth } from "./SideBar";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

export default function PaginationBar({
  disabled,
  last_page_no,
  limit,
  page,
  handlePaginationChange,
  disableBorder,
  ...props
}) {
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(page);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handlePaginationChange(null, currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < last_page_no) {
      setCurrentPage(currentPage + 1);
      handlePaginationChange(null, currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  return (
    <>
      {last_page_no > 1 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            right: "0",
            left: "0",
            backgroundColor: "#fff",
            width: "100%",
            height: "70px",
            paddingLeft: `${drawerWidth}px `,
            justifyContent: "space-between",
            borderTop: disableBorder ? "" : "1px solid #e0e0e0",
            ...props?.sx,
          }}
        >
          <Button
            onClick={handlePrevClick}
            disabled={currentPage === 1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
              padding: "12px 14px",
              fontWeight: "500",
              marginLeft: "22px",
            }}
          >
            <ArrowBack sx={{ color: "inherit", fontSize: "18px" }} />
            <Typography
              fontStyle={{
                fontSize: "14px",
              }}
            >
              Previous
            </Typography>
          </Button>
          <Pagination
            count={last_page_no ? last_page_no : 0}
            disabled={disabled}
            onChange={handlePaginationChange}
            page={page}
            hidePrevButton
            hideNextButton
            size="large"
            renderItem={(item) => (
              <PaginationItem
                component={IconButton}
                disabled={item.disabled}
                onClick={item.onClick}
                style={
                  item.selected
                    ? {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.main,
                      }
                    : {
                        color: "#667085",
                      }
                }
                {...item}
              />
            )}
          />

          <Button
            onClick={handleNextClick}
            disabled={currentPage === last_page_no}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
              padding: "12px 14px",
              borderRadius: "4px",
              fontWeight: "500",
              marginRight: "22px",
            }}
          >
            <Typography
              fontStyle={{
                fontSize: "14px",
              }}
            >
              Next
            </Typography>
            <ArrowForward sx={{ color: "inherit", fontSize: "18px" }} />
          </Button>
        </Stack>
      )}
    </>
  );
}
