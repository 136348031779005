// libraries
import { BrowserRouter, Route, Routes } from "react-router-dom";

// material UI styles
import { ThemeProvider } from "@mui/material/styles";
// import { StyledEngineProvider } from "@mui/material/styles";

// material UI components
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// custom components
import Error from "./components/helpers/error";
import SideBar from "./components/SideBar";
import ErrorPageHandler from "./components/error/ErrorPageHandler";

//theme
import { light } from "./utils/theme";

// custom hooks
import { AuthProvider } from "./context/authContext";
import useAuth from "./hooks/useAuth";
//context
import GlobalContextProvider from "./context/globalContext";
// custom pages
import Dashboard from "./pages/dashboard";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import VerifyEmail from "./pages/verifyEmail";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import Credits from "./pages/credits";
import CreditRequestsDetails from "./pages/creditRequestsDetails";
import StandardUsers from "./pages/standardUsers";
import CallHistories from "./pages/usageHistories";
import Profile from "./pages/profile";
import CreditDetails from "./pages/creditDetails";
import CreditRequests from "./pages/creditRequests";
import Checkout from "./pages/checkout";
import Success from "./pages/success";
import Cancel from "./pages/cancel";
// import CallDetails from "./pages/CallDetails";
import UserDetails from "./pages/userDetails";
// utils
import urlpatterns from "./utils/urls.json";
//3rd party
import { SnackbarProvider } from "notistack";
import Error4xx from "./components/error/Error4xx";
import ServicesInfo from "./pages/servicesInfo";

const AppRoutes = () => {
  const [auth, dispatch] = useAuth();
  return (
    <BrowserRouter>
      <SideBar auth={auth} dispatch={dispatch}>
        <Routes>
          <Route path={urlpatterns.dashboard} element={<Dashboard />} exact />
          <Route path={urlpatterns.credits} element={<Credits />} exact />
          <Route
            path={urlpatterns.credit_requests}
            element={<CreditRequests />}
            exact
          />
          <Route
            path={urlpatterns.credit_details}
            element={<CreditDetails />}
            exact
          />
          <Route
            path={urlpatterns.credit_requests_details}
            element={<CreditRequestsDetails />}
            exact
          />
          <Route
            path={urlpatterns.credit_requests_checkout}
            element={<Checkout />}
            exact
          />
          <Route
            path={urlpatterns.credit_requests_payment_success}
            element={<Success />}
            exact
          />
          <Route
            path={urlpatterns.credit_requests_payment_cancel}
            element={<Cancel />}
            exact
          />
          <Route path={urlpatterns.profile} element={<Profile />} exact />
          <Route
            path={urlpatterns.usage_histories}
            element={<CallHistories />}
            exact
          />
          <Route path={urlpatterns.users} element={<StandardUsers />} exact />
          <Route
            path={urlpatterns.users_details}
            element={<UserDetails />}
            exact
          />
          <Route
            path={urlpatterns.services_info}
            element={<ServicesInfo />}
            exact
          />
          <Route
            path={urlpatterns.confirm_email}
            element={<VerifyEmail />}
            exact
          />
          <Route
            path={urlpatterns.forgot_password}
            element={<ForgotPassword />}
            exact
          />
          <Route
            path={urlpatterns.reset_password}
            element={<ResetPassword />}
            exact
          />
          <Route path={urlpatterns.signin} element={<SignIn />} exact />
          <Route path={urlpatterns.registration} element={<SignUp />} exact />

          <Route path="/error/:error" element={<ErrorPageHandler />} />
          <Route path="*" element={<Error4xx />} />
        </Routes>
      </SideBar>
    </BrowserRouter>
  );
};

const Layout = () => {
  const matches = useMediaQuery("(max-width:1199px)");
  return (
    <>
      {matches ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Typography
            component="h3"
            sx={{ fontWeight: "700", fontSize: "calc(.5rem + 3vw)" }}
          >
            Oops!! Device Not Supported!
          </Typography>
          <Typography
            component="h3"
            sx={{ fontWeight: "400", fontSize: "calc(.5rem + 1vw)" }}
          >
            This device resolution is not supported.
          </Typography>
        </Stack>
      ) : (
        <Box
          elevation={0}
          sx={{ display: { xs: "none", lg: "block", xl: "block" } }}
        >
          <Box positon="absolute" height="100%"></Box>
          <AppRoutes />
        </Box>
      )}
    </>
  );
};

export default function App() {
  return (
    <ThemeProvider theme={light}>
      {navigator.cookieEnabled ? (
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <GlobalContextProvider>
            <AuthProvider>
              <Layout />
            </AuthProvider>
          </GlobalContextProvider>
        </SnackbarProvider>
      ) : (
        <Error message="Cookies are not enabled! Please enable them, we use cookies for authentication" />
      )}
    </ThemeProvider>
  );
}
