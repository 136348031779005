import { Paper, Stack, Typography, Button } from "@mui/material";
import { Container } from "@mui/system";
import AuthHOC from "../hoc/AuthHOC";
import CancelLogo from "../assets/Cancel.svg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import urls from "../utils/urls.json";
import { Link } from "react-router-dom";

const breadcrumbs = [
  <Typography key="1"></Typography>,
  <Link key="2" to={urls.credits}>
    Back to Credits
  </Link>,
];

function Cancel() {
  return (
    <Container
      maxWidth="md"
      style={{
        padding: "120px 0px 0px 0px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Stack direction="column" spacing={3}>
        <Stack justifyContent="center" alignItems="center">
          <Paper
            elevation={0}
            style={{
              width: "100%",
              padding: "80px",
              borderRadius: "12px",
            }}
          >
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              spacing={3}
            >
              <img src={CancelLogo} alt="done" />

              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "700",
                  color: "#0F0F0F",
                }}
              >
                Payment Failed
              </Typography>
            </Stack>
          </Paper>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs
            separator={<NavigateBeforeIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Button
            variant="contained"
            component="a"
            href="mailto:partners@neurobit.com"
            sx={{
              fontWeight: "500",
              padding: "20px 24px",
              fontSize: "18px",
              lineHeight: "23px",
              textAlign: "center",
              color: "#FFFFFF",
              background: "#2f7ec7",
              ":hover": {
                background: "#2b77be",
              },
            }}
          >
            Contact Support
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default AuthHOC(Cancel);
