import React from "react";
import Status from "./status";
//don't change the order of the if conditions
export const cellData = ({
  cell,
  status,
  statusColorMode,
  enableStatusTooltip,
  statusDetail,
  extraData,
  ...props
}) => {
  function isValidDate(dateString) {
    //accepted format - 2018-08-01T18:30:00.000Z
    const _regExp = new RegExp(
      "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
    );
    return _regExp.test(dateString);
  }
  if (status) {
    return (
      <Status
        status={`${cell}`}
        colorMode={statusColorMode}
        enableStatusTooltip={enableStatusTooltip}
        statusDetail={statusDetail}
        extraData={extraData}
      />
    );
  }
  if (isValidDate(cell)) {
    return new Date(cell).toDateString();
  }
  if (typeof cell === "boolean") {
    return cell ? (
      <Status
        status={`Active`}
        colorMode={statusColorMode}
        enableStatusTooltip={enableStatusTooltip}
        statusDetail={statusDetail}
        extraData={extraData}
      />
    ) : (
      <Status
        status={`Inactive`}
        colorMode={statusColorMode}
        enableStatusTooltip={enableStatusTooltip}
        statusDetail={statusDetail}
        extraData={extraData}
      />
    );
  }
  if (cell === null || cell === "") {
    return "-";
  }
  if (typeof cell === "number" || !isNaN(cell)) {
    if (Number.isInteger(cell)) return cell;
    else return parseFloat(cell).toFixed(2);
  }
  return cell;
};
