//react
import { useRef } from "react";

/////////////////MUI/////////////////////////
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
///////////////Internal /////////////
import ServiceCard from "../components/services/ServiceCard";
// internal
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
//react
import { useEffect, useState } from "react";
//hooks
import useAxios from "../hooks/useAxios";
//utils & helper
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//libs
import { useSnackbar } from "notistack";

///////////Main Code////////////
function ServicesInfo({ ...props }) {
  const [servicesData, setServicesData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setIsFetchingData(true);
      try {
        const config = axiosConfig({
          uri: "/accounts/supervisor/services",
          method: "GET",
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServicesData(response.data.results);
        setIsFetchingData(false);
      } catch (error) {
        if (error.message !== "canceled") setIsFetchingData(false);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
  }, [enqueueSnackbar, closeSnackbar]);
  return (
    <Box mb="2rem">
      <Stack sx={{ width: "100%", my: "30px" }} direction="column" spacing={2}>
        <Stack direction="column" spacing={1} sx={{}}>
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
            Services - Credits Info
          </Typography>
          <Typography fontSize="16px" fontWeight="400" color="#898989">
            View which service requires how much credits for processing
          </Typography>
        </Stack>
      </Stack>
      <>
        {isFetchingData ? (
          <ComponentLoader loading={isFetchingData} transparent={true} />
        ) : servicesData ? (
          <Grid
            container
            spacing={3}
            sx={{ width: "100%" }}
            alignItems="stretch"
          >
            {servicesData ? (
              servicesData.map((data, index) => {
                return (
                  <Grid item xs={6} key={index}>
                    <ServiceCard data={data} />
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Typography component="p">No Data Available</Typography>
          </Stack>
        )}
      </>
    </Box>
  );
}

export default AuthHOC(ServicesInfo);
