// libraries
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// material UI styles
import withStyles from "@mui/styles/withStyles";

// material UI components
import Radio from "@mui/material/Radio";
import Toolbar from "@mui/material/Toolbar";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
// import MuiDialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// material UI icons
import { ListItemIcon, Stack } from "@mui/material";
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";

// dialog icons
import Stripe from "../../assets/dialogs/credit_request/stripe.svg";
import Cheque from "../../assets/dialogs/credit_request/credit-card.svg";

// import Cheque from "../../assets/cheque.png"
// import Stripe from "../../assets/stripe.png"
//hooks
import useAxios from "../../hooks/useAxios";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import { useSnackbar } from "notistack";
import TextField from "../forms/FormComponents/TextField";
const LOCAL_FORM_VALIDATION = yup.object().shape({
  totalCredits: FORM_VALIDATION["numberOfCredits"],
  purpose: FORM_VALIDATION["purpose"],
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function EditCreditRequestForm(props) {
  const [paymentMode, setPaymentMode] = useState(
    props?.location?.state?.credit?.payment.mode
  );

  const FORM_INITIAL_VALUES = {
    totalCredits: parseFloat(props?.location?.state?.credit?.added).toFixed(2),
    purpose: props?.location?.state?.credit?.purpose,
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  //callback for handling form submit
  const handleSubmit = async (added, purpose, paymentMode) => {
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/credits/request/supervisor/${props?.location?.state?.id}/`,
        data: {
          payment_mode: paymentMode,
          added,
          purpose,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      props.setData(response.data);
      props.handleClose();
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      props.handleClose();
    }
  };
  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES}
      validationSchema={LOCAL_FORM_VALIDATION}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await handleSubmit(values?.totalCredits, values?.purpose, paymentMode);
        props.handleClose();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <DialogTitle onClose={props.handleClose}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Toolbar>
                      <Box fontWeight={700} fontStyle="normal" width="100%">
                        <Typography
                          style={{
                            color: "#022539",
                            fontWeight: "500",
                            fontSize: "24px",
                          }}
                          align="center"
                        >
                          Edit credit request
                        </Typography>
                      </Box>
                    </Toolbar>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                paddingLeft="20px"
                paddingRight="20px"
                spacing={4}
              >
                <Stack direction="column" spacing={2}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    textAlign="left"
                  >
                    Number of credits
                  </Typography>
                  <TextField
                    placeholder="Enter the number of credits"
                    inputProps={{
                      style: {
                        padding: "12px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                      },
                    }}
                    type="number"
                    name="totalCredits"
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        padding: "12px",
                        background: "#F6F6F6",
                      },
                    }}
                  />
                </Stack>

                <Stack direction="column" spacing={2}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    textAlign="left"
                  >
                    Purpose
                  </Typography>
                  <TextField
                    placeholder="Eg: Research, Clinical Trial, etc."
                    inputProps={{
                      style: {
                        padding: "12px",
                        fontSize: "16px",
                        color: "#0F0F0F",
                      },
                    }}
                    type="text"
                    name="purpose"
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "4px",
                        border: "1px solid #E0E0E0",
                        padding: "12px",
                        background: "#F6F6F6",
                      },
                    }}
                  />
                </Stack>

                <Stack direction="column">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    textAlign="left"
                  >
                    Mode of Payment
                  </Typography>
                  <FormControl
                    component="fieldset"
                    style={{
                      width: "85%",
                      color: "#0F0F0F",
                    }}
                    required
                  >
                    <RadioGroup
                      aria-label="payment-method"
                      name="payment-method"
                      value={paymentMode}
                      onChange={(event) => setPaymentMode(event.target.value)}
                    >
                      <FormControlLabel
                        value="STRIPE"
                        control={<Radio style={{ color: "#000000" }} />}
                        label={
                          <React.Fragment>
                            <ListItemIcon
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <img src={Stripe} alt="Stripe" width="24" />

                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  marginTop: "2px",
                                  color: "#4A4A4A",
                                }}
                                textAlign="left"
                              >
                                Stripe (Online payment)
                              </Typography>
                            </ListItemIcon>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        value="CHEQUE"
                        control={<Radio style={{ color: "#000000" }} />}
                        label={
                          <React.Fragment>
                            <ListItemIcon
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <img src={Cheque} alt="Stripe" width="24" />

                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  marginTop: "2px",
                                  color: "#4A4A4A",
                                }}
                                textAlign="left"
                              >
                                Cheque (Offline payment)
                              </Typography>
                            </ListItemIcon>
                          </React.Fragment>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  paddingBottom="10px"
                >
                  <Button
                    variant="text"
                    cursor="pointer"
                    style={{
                      height: "34px",

                      color: "#959595",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                    onClick={props.handleClose}
                    disableElevation
                  >
                    Cancel
                  </Button>

                  <ButtonWrapper
                    disableElevation
                    sx={{
                      maxHeight: "34px",
                      borderRadius: "4px",
                      mt: 0,
                      p: "8px 32px",
                    }}
                    disabled={!isValid || isSubmitting}
                  >
                    Edit Request
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </DialogContent>
          </Form>
        );
      }}
    </Formik>
  );
}

EditCreditRequestForm.propTypes = {
  handleClose: PropTypes.func,
};
