// libraries
import { useEffect, useState, useCallback, useRef } from "react";

// material UI components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
//INTERNAL
import CreditConsumed from "../components/info-card/CreditConsumed";
import Chart from "../components/Chart";
import Requests from "../components/info-card/Requests";
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
import AddCreditRequestForm from "../components/forms/addCreditRequestForm";
import { useSnackbar } from "notistack";
// utils
import {
  axiosConfig,
  formatDateTimeFns,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import PaginationBar from "../components/PaginationBar";
//hooks
import useAxios from "../hooks/useAxios";
import TableComponent from "../components/Table";
import { Dialog } from "@mui/material";
const cols = [
  {
    name: "CREDITS ADDED",
    accessor: "added",
  },
  {
    name: "CREDITS AVAILABLE",
    accessor: "available",
  },
  {
    name: "CREDITS USED",
    accessor: "used",
  },
  {
    name: "ISSUED ON",
    accessor: "root",
    getAccessor: (row) => formatDateTimeFns(row.added_on),
  },
  {
    name: "VALIDITY TILL",
    accessor: "root",
    getAccessor: (row) => formatDateTimeFns(row.validity),
  },
  {
    name: "STATUS",
    accessor: "active",
  },
];

const createDate = (datestring) => {
  const options = {
    day: "numeric",
    month: "short",
  };

  const date = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datestring)
  );
  return date;
};
function createGraphData(data) {
  const graphData = data.map((element, index) => {
    createDate(element.created_on__date);
    return {
      date: createDate(element.created_on__date),
      credits: element.credits_used,
    };
  });
  return graphData;
}

function Credits({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [chartData, setChartData] = useState([]);
  const [fetchingChartData, setFetchingChartData] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 5;
  const [fetchingCredits, setFetchingCredits] = useState(true);
  const [creditRequestSummary, setCreditRequestSummary] = useState(null);

  // Credit Request Dialog
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  //fetch credits callback
  const fetchCredits = useCallback(
    async (abortController) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/`,
          params: {
            ordering: "validity",
            active: true,
            page: page,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        return response;
      } catch (error) {
        if (error.message !== "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [closeSnackbar, enqueueSnackbar, page]
  );

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      try {
        //Todo: change url
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request/summary`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setCreditRequestSummary(response.data);
      } catch (error) {
        if (error && error.message !== "canceled")
          httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
  }, [enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    const abortController = new AbortController();
    let isMounted = true;
    setFetchingCredits(true);
    fetchCredits(abortController)
      .then((response) => {
        if (isMounted && response) {
          setFetchingCredits(false);
          setData(response.data);
        }
      })
      .catch((error) => {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        setFetchingCredits(false);
      });
    return () => {
      abortController.abort();
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCredits]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/usage-history-overview`,
          params: { operation: "get-basic-info" },
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        // if (isMounted) {
        setChartData((prevState) => {
          return { ...prevState, credits: createGraphData(response.data) };
        });
        setFetchingChartData(false);
        setLoading(false);
        // }
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  //for sending api request for creating request.
  const createRequest = async (added, paymentMode, purpose) => {
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/credits/request/supervisor/`,
        data: { added, purpose, payment_mode: paymentMode },
      });
      await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        "Credit Request Sent",
        enqueueSnackbar,
        closeSnackbar,
        "Credit request has been sent successfully. Waiting for approval"
      );
      setCreditRequestSummary((prev) => ({
        ...prev,
        pending: prev?.pending + 1,
      }));
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    <>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          minHeight="calc(100vh - 120px)"
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
          sx={{ background: "transparent" }}
        />
      ) : (
        <Grid
          container
          sx={{ height: "100%", width: "100%" }}
          className="Credits-Page-Container"
        >
          <Grid container item spacing={5}>
            {/* {START: Up Side} */}
            <Grid item xs={12} lg={12}>
              <Grid container spacing={7}>
                <Grid item sm={6} lg={12} xl={6}>
                  <Grid container spacing={3}>
                    {/* START: Credits Used */}
                    <Grid item xs={6}>
                      <CreditConsumed
                        vertical={true}
                        action_text="Request Credits"
                        action_function={handleClickOpen}
                      />
                    </Grid>
                    {/* END: Credits Used */}
                    {/* START: Credit Request */}
                    <Grid item xs={6}>
                      <Requests data={creditRequestSummary || []} />
                    </Grid>
                    {/* END: Credit Request */}
                  </Grid>
                </Grid>

                {/* {START: Graph} */}
                <Grid item sm={6} lg={12} xl={6}>
                  <Paper
                    elevation={0}
                    style={{
                      height: "100%",
                      marginBottom: "-90px",
                    }}
                  >
                    {!fetchingChartData && (
                      <Chart
                        data={chartData.credits}
                        xKey="date"
                        yKey="credits"
                      />
                    )}
                  </Paper>
                </Grid>
                {/* END: Graph */}
              </Grid>
            </Grid>
            {/* END: Up Side */}
            {/* START: Down Side */}
            <Grid item xs={12} pb={2}>
              <Paper elevation={0}>
                <TableComponent
                  cols={cols}
                  rows={{
                    count: data?.count,
                    results: data.results,
                  }}
                  loading={fetchingCredits}
                  rowLink={{
                    linkKey: "id",
                    linkTo: "/credits",
                  }}
                  paginate={
                    data?.count ? (
                      <PaginationBar
                        disabled={loading}
                        page={page}
                        last_page_no={Math.ceil(
                          data?.count ? data?.count / PAGE_SIZE : 1
                        )}
                        limit={data?.results?.length || 0}
                        handlePaginationChange={(_, value) => {
                          setPage(value);
                        }}
                        sx={{ pl: "0", position: "unset" }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  tableRowStyles={{
                    backgroundColor: "#fff",
                    transition: "background-color .2s ease",
                    ":hover": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Paper>
            </Grid>
            {/* END: Down Side */}
          </Grid>
          <Dialog onClose={handleClose} open={open} fullWidth>
            <AddCreditRequestForm
              handleClose={handleClose}
              createRequest={createRequest}
            />
          </Dialog>
        </Grid>
      )}
    </>
  );
}

export default AuthHOC(Credits);
