import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import urlpatterns from "../utils/urls.json";

// import { DEBUG } from "../utils/helpers";
import { useContext } from "react";
import { globalContext } from "../context/globalContext";
export default function AuthHOC(WrappedComponent) {
  function Component(props) {
    const globalCtx = useContext(globalContext);
    const [auth, dispatch] = useAuth();
    if (!auth?.authenticated) {
      return <Navigate to={urlpatterns.signin} />;
    } else if (auth?.authenticated && auth?.user?.type === "SUPERVISOR") {
      return <WrappedComponent user={auth.user} dispatch={dispatch} />;
    } else if (auth?.user) {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
      return <Navigate to={urlpatterns.signin} />;
    }
  }

  return Component;
}
