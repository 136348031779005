// libraries
import { useEffect, useState, useRef } from "react";

// material UI components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// custom components
import TableComponent from "../components/Table";
import Chart from "../components/Chart";
import CreditConsumed from "../components/info-card/CreditConsumed";
import TotalUsers from "../components/info-card/TotalUsers";

// custom components
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
import TableTitle from "../components/table_title";
// utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";

//3rd party
import { useSnackbar } from "notistack";
// import { globalContext } from "../context/globalContext";
//hooks
import useAxios from "../hooks/useAxios";
import CustomServicesCell from "../components/custom_service";

const inititalState = {
  clients: [],
  credits: [],
};

const callsColumns = [
  {
    name: "EMAIL",
    accessor: "message",
    getAccessor: (el) => {
      return el.consumer?.user?.email;
    },

    sx: {
      rowCell: {
        maxWidth: "100px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    title: (el) => {
      return el?.message?.consumer?.user?.email;
    },
  },
  {
    name: "CREDITS USED",
    accessor: "message",
    getAccessor: (el) => {
      return el?.net_credits_consumed;
    },
  },
  {
    name: "SERVICE USED",
    getAccessor: (el) => {
      return el?.message?.call;
    },
    component: CustomServicesCell,
  },
];
const creditsColumns = [
  {
    name: "CREDITS ADDED",
    accessor: "added",
  },
  {
    name: "CREDITS AVAILABLE",
    accessor: "available",
  },
  {
    name: "CREDITS USED",
    accessor: "used",
  },
  {
    name: "STATUS",
    accessor: "active",
  },
];
const createDate = (datestring) => {
  const options = {
    day: "numeric",
    month: "short",
  };

  const date = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datestring)
  );
  return date;
};
function createGraphData(data) {
  const graphData = data.map((element, index) => {
    createDate(element.created_on__date);
    return {
      date: createDate(element.created_on__date),
      credits: element.credits_used,
    };
  });

  return graphData;
}

function Dashboard({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(inititalState);
  const [chartData, setChartData] = useState([]);
  const [consumerCount, setConsumerCount] = useState(0);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [creditsData, setCreditsData] = useState([]);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  //function fetched the dashboard data except the graph data

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);
        const config = axiosConfig({
          method: "GET",
          uri: `/accounts/supervisor/`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        isMounted &&
          setData({
            clients: response.data.clients,
            credits: response.data.credits,
          });

        const config2 = axiosConfig({
          method: "GET",
          uri: `/accounts/supervisor/consumers/`,
          withCredentials: true,
          params: { page: 1, page_size: 1 },
        });
        const totalConsumerResponse = await axiosInstance.current({
          ...config2,
          signal: abortController.signal,
        });
        isMounted && setConsumerCount(totalConsumerResponse.data.count);
        isMounted && setLoading(false);
      } catch (error) {
        if (error.message !== "canceled") {
          setError(true);
          setLoading(false);
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    };
    //calling callback
    fetchDashboardData();
    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [reload, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    (async () => {
      try {
        setLoading(true);
        setError(null);
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/usage-history-overview`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        isMounted &&
          setChartData((prevState) => {
            return { ...prevState, credits: createGraphData(response.data) };
          });
        isMounted && setLoading(false);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        if (error.message !== "canceled") {
          setError(true);
          setLoading(false);
        }
      }
    })();
    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/activity`,
          withCredentials: true,
          params: { page: 1, page_size: 10, action: "credits.deducted" },
        });
        const response = await axiosInstance.current({ ...config });
        setCreditsData(response.data.results);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
  }, [closeSnackbar, enqueueSnackbar]);

  return (
    <div style={{ minHeight: "95vh" }}>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          minHeight="calc(100vh - 120px)"
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <>
          <Grid container spacing={{ xs: 8, md: 6, lg: 5, xl: 10 }}>
            {/* START: Credits and Users */}
            <Grid item xs={12} lg={12} sm={4} xl={4}>
              <Grid container spacing={3}>
                <Grid item lg={6} xl={12}>
                  <CreditConsumed loading={loading} />
                </Grid>
                <Grid item lg={6} xl={12}>
                  <TotalUsers loading={loading} consumerCount={consumerCount} />
                </Grid>
              </Grid>
            </Grid>
            {/* END: Credits and Users */}

            {/* START: Graph */}
            <Grid item xs={12} sm={8} lg={12} xl={8}>
              <Paper
                elevation={0}
                sx={{
                  height: "100%",
                  marginBottom: {
                    xs: "0",
                    sm: "-90px",
                  },
                  borderRadius: "10.1226px",
                }}
              >
                <Chart data={chartData.credits} xKey="date" yKey="credits" />
              </Paper>
            </Grid>
            {/* END: Graph */}
          </Grid>
          <br />
          <br />
          {/* START: Down Side */}
          <Grid container spacing={{ xs: 8, md: 5, lg: 5, xl: 4 }}>
            {/* START: Previous Call History */}
            <Grid item xs={12} sm={6} lg={12} xl={5}>
              <Paper
                elevation={0}
                sx={{
                  boxShadow:
                    "0px 5.06667px 29.3867px rgba(112, 112, 112, 0.06)",
                }}
              >
                <TableTitle title="Usage Histories" />
                <TableComponent
                  cols={callsColumns}
                  rows={{
                    count: creditsData?.length || 0,
                    results: creditsData,
                  }}
                  enableViewAll={{
                    title: "View All",
                    viewAll: true,
                    viewLink: "/usage",
                    maxResults: 3,
                  }}
                  tableRowStyles={{ height: "70px" }}
                />
              </Paper>
            </Grid>
            {/* END: Previous Call History */}

            {/* START: Recent Credits */}
            <Grid item xs={12} sm={6} lg={12} xl={7}>
              <Paper
                elevation={0}
                sx={{
                  boxShadow:
                    "0px 5.06667px 29.3867px rgba(112, 112, 112, 0.06)",
                }}
              >
                <TableTitle title="Recent Credits" />
                <TableComponent
                  cols={creditsColumns}
                  rows={{
                    count: data?.credits?.length || 0,
                    results: data?.credits,
                  }}
                  enableViewAll={{
                    title: "View All",
                    viewAll: true,
                    viewLink: "/credits",
                    maxResults: 4,
                  }}
                  tableRowStyles={{ height: "70px" }}
                />
              </Paper>
            </Grid>
            {/* END:  Recent Credits */}
          </Grid>
        </>
      )}
    </div>
  );
}
export default AuthHOC(Dashboard);
