// react
import { useRef } from "react";
import PropTypes from "prop-types";

// material UI components
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";

// material UI icons
import { Stack } from "@mui/material";
import { axiosConfig } from "../../utils/helpers";
import urls from "../../utils/urls.json";
//hooks
import useAxios from "../../hooks/useAxios";
export default function CancelRequestForm(props) {
  const navigate = useNavigate();

  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const config = axiosConfig({
      method: "DELETE",
      uri: `/credits/request/supervisor/${props.creditrequest_id}/`,
    });
    await axiosInstance.current({ ...config });
    navigate(urls.credit_requests);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }} align="center">
      <DialogTitle onClose={props.handleCloseCancelOpen}>
        <Stack direction="column" spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Typography
                  style={{
                    color: "#0F0F0F",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                  align="left"
                >
                  Are you sure you want to Cancel Request?{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#959595",
              }}
              align="left"
            >
              Once the credit request is cancelled, all the requested details
              will be permenantly deleted.{" "}
            </Typography>
          </Stack>

          <Stack direction="column" spacing={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="button"
                cursor="pointer"
                style={{
                  height: "34px",
                  color: "#959595",
                  cursor: "pointer",
                }}
                onClick={props.handleCloseCancelOpen}
              >
                Back
              </Typography>
              <Button
                variant="contained"
                style={{
                  height: "34px",
                  backgroundColor: "#2F7EC7",
                  textTransform: "none",
                }}
                disableElevation
                onClick={handleSubmit}
              >
                Cancel Request
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </form>
  );
}

CancelRequestForm.propTypes = {
  handleCloseCancelOpen: PropTypes.func,
};
