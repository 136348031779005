// libraries
import { useState, useRef, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
// material UI components
import Grid from "@mui/material/Grid";

// custom components
import UpdateProfileForm from "../components/forms/updateProfile";
import AuthHOC from "../hoc/AuthHOC";
//hooks
import useAxios from "../hooks/useAxios";
// utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
//internal
import UserDetail from "../components/user_details/UserDetail";

function Profile({ user, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [managerInfo, setManagerInfo] = useState(null);
  const [licenseInfo, setLicenseInfo] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const abortController = useMemo(() => new AbortController(), []);

  //fetching manager info
  useEffect(() => {
    (async () => {
      try {
        const config = axiosConfig({
          uri: "/accounts/supervisor/manager-info/",
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
        });
        setManagerInfo(response.data);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [abortController, closeSnackbar, enqueueSnackbar]);

  //fetching license type
  useEffect(() => {
    (async () => {
      try {
        const config = axiosConfig({
          uri: "/accounts/supervisor/license/",
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
        });
        setLicenseInfo(response.data);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [abortController, closeSnackbar, enqueueSnackbar]);

  const updateProfile = async (firstName, lastName, email) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/accounts/supervisor/`,
        params: { operation: "update-profile" },
        data: { first_name: firstName, last_name: lastName },
      });

      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      dispatch({ type: "update_user", user: response.data });
      notificationsHandler(
        "success",
        "Profile Updated Successfully",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    setLoading(false);
  };

  const updatePassword = async (password1, password2, old_password) => {
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/change/`,
        data: {
          new_password1: password1,
          new_password2: password2,
          old_password,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        "Password Updated Successfully",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  const userData = [
    [
      {
        Name: () => {
          if (managerInfo)
            return managerInfo?.first_name + " " + managerInfo?.last_name;
          return "";
        },
      },
    ],
    [{ Email: managerInfo?.email ? managerInfo?.email : "" }],
  ];
  return (
    <>
      <Grid container spacing={1} sx={{ mt: "8px" }}>
        {/* START: Body Row */}
        <Grid item xs={12}>
          <UpdateProfileForm
            firstName={user?.first_name}
            lastName={user?.last_name}
            email={user?.email}
            created_on={user?.date_joined}
            loading={loading}
            updateProfile={updateProfile}
            updatePassword={updatePassword}
            licenseInfo={licenseInfo}
          />
        </Grid>
        <Grid item xs={12}>
          <UserDetail
            title="Other Info"
            tagLine="View Manager Details Here"
            disableAction
            data={userData}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AuthHOC(Profile);
