// import keys from "../../keys.json";
import logo from "../../assets/logo.svg";
import {
  Container,
  Paper,
  Typography,
  Stack,
  FormControl,
} from "@mui/material";
import { Link } from "react-router-dom";
import urlpatterns from "../../utils/urls.json";

//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  first_name: FORM_VALIDATION["first_name"],
  last_name: FORM_VALIDATION["last_name"],
  email: FORM_VALIDATION["email"],
  password: FORM_VALIDATION["password"],
});
const FORM_INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
};

export default function SigninForm(props) {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "768px",
            padding: "48px 64px 60px 64px",
            borderRadius: "12px",
            boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
          }}
        >
          <Stack direction="column">
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              py={5}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Sign Up
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              // spacing={2}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <Formik
                  initialValues={FORM_INITIAL_VALUES}
                  validationSchema={LOCAL_FORM_VALIDATION}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    await props.handleSubmit(
                      values?.email,
                      values?.password,
                      values?.first_name,
                      values?.last_name
                    );
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, isValid }) => {
                    return (
                      <Form>
                        <Stack my={2} direction="column" spacing={3}>
                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              First Name
                            </Typography>
                            <TextField
                              fullWidth
                              type="text"
                              name="first_name"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  background: "transparent",
                                  ":hover": {
                                    borderRadius: "8px",
                                  },
                                },
                              }}
                              sx={{
                                background: "transparent",
                                "& input": {
                                  height: "30px",
                                  borderRadius: "8px",
                                  border: "1px solid #E0E0E0",
                                  padding: "12px",
                                  background: "#F6F6F6",
                                },
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              Last Name
                            </Typography>
                            <TextField
                              type="text"
                              name="last_name"
                              variant="filled"
                              fullWidth
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  background: "transparent",
                                  ":hover": {
                                    borderRadius: "8px",
                                  },
                                },
                              }}
                              sx={{
                                background: "transparent",
                                "& input": {
                                  height: "30px",
                                  borderRadius: "8px",
                                  border: "1px solid #E0E0E0",
                                  padding: "12px",
                                  background: "#F6F6F6",
                                },
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              Email address
                            </Typography>
                            <TextField
                              fullWidth
                              type="text"
                              name="email"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  background: "transparent",
                                  ":hover": {
                                    borderRadius: "8px",
                                  },
                                },
                              }}
                              sx={{
                                background: "transparent",
                                "& input": {
                                  height: "30px",
                                  borderRadius: "8px",
                                  border: "1px solid #E0E0E0",
                                  padding: "12px",
                                  background: "#F6F6F6",
                                },
                              }}
                            />
                          </FormControl>
                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              Password
                            </Typography>
                            <TextField
                              type="password"
                              name="password"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  background: "transparent",
                                  ":hover": {
                                    borderRadius: "8px",
                                  },
                                },
                              }}
                              sx={{
                                background: "transparent",
                                "& input": {
                                  height: "30px",
                                  borderRadius: "8px",
                                  border: "1px solid #E0E0E0",
                                  padding: "12px",
                                  background: "#F6F6F6",
                                },
                              }}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction="column" spacing={5} py={2}>
                          <ButtonWrapper
                            fullWidth
                            disabled={!isValid || isSubmitting}
                          >
                            Sign Up
                          </ButtonWrapper>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
                <Stack direction="column" marginTop="28px">
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#8A8A8A",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      width: "100%",
                      marginTop: "72px",
                    }}
                  >
                    Already have an account?
                    <Typography
                      component={Link}
                      to={urlpatterns.signin}
                      color="#2F7EC7"
                      disabled={props.loading}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "21.78px",
                        pl: "8px",
                      }}
                    >
                      Log In Here{" "}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
