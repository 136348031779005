import PropTypes from "prop-types";
//mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import withStyles from "@mui/styles/withStyles";
import { Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";
const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 15px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0px 15px",
  },
}))(MuiDialogActions);

// const CustomTextField = styled(TextField, {
//   name: "CustomTextField",
//   slot: "Root",
//   lable: "CustomTextField",
// })(({ theme }) => ({
//   background: "#F6F6F6",
//   border: "1px solid #E0E0E0",
//   borderRadius: "5px",
//   width: "100%",
//   marginTop: "12px",
//   marginBottom: "16px",
// }));
const CustomTypography = styled(Typography, {
  name: "CustomTypography",
  slot: "Root",
  lable: "CustomTypography",
})(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "21px",
  color: "#0f0f0f",
  textAlign: "left",
  display: "block",
  marginBottom: "4px",
}));

const LOCAL_FORM_VALIDATION = yup.object().shape({
  first_name: FORM_VALIDATION["first_name"],
  last_name: FORM_VALIDATION["last_name"],
  email: FORM_VALIDATION["email"],
  password: FORM_VALIDATION["password"],
});
const FORM_INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
};
export default function AddConsumerForm(props) {
  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES}
      validationSchema={LOCAL_FORM_VALIDATION}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await props.createConsumer(
          values?.email,
          values?.password,
          values?.first_name,
          values?.last_name
        );
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Box sx={{ padding: "10px 16px 16px 8px" }}>
              <DialogTitle onClose={props.handleClose}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <Typography
                        component="div"
                        variant="body2"
                        style={{
                          width: "100%",
                          color: "#022539",
                        }}
                        align="center"
                      >
                        <Typography
                          marginBottom="20px"
                          fontWeight={500}
                          fontStyle="normal"
                          fontSize="18px"
                        >
                          Add New User
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Box>
                        {/* <IconButton onClick={props.handleClose} size="large">
                          <CloseIcon />
                        </IconButton> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Stack>
                  <Box sx={{ mb: "32px" }}>
                    <CustomTypography component="span">
                      First Name*
                    </CustomTypography>

                    <TextField
                      fullWidth
                      type="text"
                      name="first_name"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          background: "transparent",
                          ":hover": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          height: "30px",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "6px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: "32px" }}>
                    <CustomTypography component="span">
                      Last Name*
                    </CustomTypography>

                    <TextField
                      fullWidth
                      type="text"
                      name="last_name"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          background: "transparent",
                          ":hover": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          height: "30px",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "12px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: "32px" }}>
                    <CustomTypography component="span">
                      Email Id *
                    </CustomTypography>

                    <TextField
                      fullWidth
                      type="email"
                      name="email"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          background: "transparent",
                          ":hover": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          height: "30px",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "12px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: "32px" }}>
                    <CustomTypography component="span">
                      Password*
                    </CustomTypography>
                    <TextField
                      fullWidth
                      type="password"
                      name="password"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          background: "transparent",
                          ":hover": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          height: "30px",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "12px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                  </Box>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Button
                    variant="text"
                    disableElevation
                    sx={{
                      padding: "12px 12px",
                    }}
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>

                  <ButtonWrapper
                    sx={{ m: 0 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Add User
                  </ButtonWrapper>
                </Stack>
              </DialogActions>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

AddConsumerForm.propTypes = {
  createConsumer: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};
