import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import useAuth from "../hooks/useAuth";
import { useSnackbar } from "notistack";
//utils
import { httpErrorHandler } from "../utils/helpers";
import { axiosConfig, notificationsHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//internal
import SigninForm from "../components/forms/signinForm";

import { globalContext } from "../context/globalContext";

export default function SignIn() {
  const navigate = useNavigate();
  const [auth, dispatch] = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const globalCtx = useContext(globalContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (email, password) => {
    setLoading(true);

    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/auth/login/",
        data: { email, password },
      });
      const response = await axios({
        ...config,
        withCredentials: true,
      });
      dispatch({
        type: "signin",
        payload: {
          user: response.data.user,
          accessToken: {
            token: response.data.access_token,
            expiration: response.data.access_token_expiration,
          },
          refreshToken: {
            token: response.data.refresh_token,
            expiration: response.data.refresh_token_expiration,
          },
        },
        error: null,
      });
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.authenticated && auth?.user?.type !== "SUPERVISOR") {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
      notificationsHandler(
        "error",
        "Please login with correct platform credentials",
        enqueueSnackbar,
        closeSnackbar
      );
    }
  }, [
    auth.authenticated,
    auth?.user?.type,
    dispatch,
    globalCtx,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  if (auth.authenticated) {
    navigate(urlpatterns.dashboard);
  }

  return (
    <>
      <SigninForm
        loading={loading}
        handleSubmit={handleSubmit}
        dispatch={dispatch}
      />
    </>
  );
}
