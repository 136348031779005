import { useState, useCallback, useRef, useEffect } from "react";
// libraries
import { useSnackbar } from "notistack";
import { Link, useLocation, useParams } from "react-router-dom";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
//hooks
import useAxios from "../hooks/useAxios";
// custom components
import AuthHOC from "../hoc/AuthHOC";
import UserDetail from "../components/user_details/UserDetail";
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urls from "../utils/urls.json";
import Consent from "../components/consent/Consent";
import ComponentLoader from "../components/loader/ComponentLoader";

const consentMessage = {
  active: {
    title: "Are you sure you want to deactivate the account",
    description:
      "If you move forward the account of this consumer will be deactivated and consumer will no longer be able to login again!",
  },
  not_active: {
    title: "Are you sure you want to activate the account",
    description:
      "If you move forward the account of this consumer will be activated and consumer will be able to login again!",
  },
};

function UserDetails({ user }) {
  const location = useLocation();
  const { id } = useParams();
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  // fetch the data from backend
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [userEnabled, setUserEnabled] = useState(location?.state?.is_active);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const fetchCreditDetails = async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/accounts/supervisor/consumers/${id}`,
        });
        const response = await axiosInstance.current({
          ...config,
          withCredentials: true,
        });
        location.state = response.data;
        isMounted && setLoading(false);
      } catch (error) {
        if (error.message !== "canceled") {
          setError(true);
          setLoading(false);
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
      setUserEnabled(location?.state?.is_active);
    };

    // Checking the location state is undefined or not
    if (location.state === null || location.state === undefined) {
      // Fetch the data from backend
      fetchCreditDetails();
    } else {
      setLoading(false);
      setError(false);
    }
    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [id, location, reload, enqueueSnackbar, closeSnackbar]);

  const [openDialog, setOpenDialog] = useState(false);

  const deactivateUser = useCallback(async () => {
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/accounts/supervisor/consumers/`,
        data: {
          consumer_ids: [location?.state?.id],
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      setUserEnabled((prev) => !prev);
      notificationsHandler(
        "success",
        `${
          response?.data?.detail
            ? response?.data?.detail
            : "Request Successfull"
        }`,
        enqueueSnackbar,
        closeSnackbar
      );
      setOpenDialog(false);
    } catch (error) {
      if (error.message !== "canceled") {
        setOpenDialog(false);
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [closeSnackbar, enqueueSnackbar, location?.state]);

  //breadcrumbs
  const breadcrumbs = [
    <Link key="1" to={urls.users} style={{ color: "#969696" }}>
      Technologist's
    </Link>,
    <Typography
      key="2"
      color="#969696"
      sx={{
        maxWidth: "150px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {`${location?.state?.first_name} ${location?.state?.last_name}`}
    </Typography>,
  ];

  //for user info
  const userData = [
    [
      {
        "First Name": () => {
          return location?.state?.first_name;
        },
      },
    ],
    [
      {
        "Last Name": () => {
          return location?.state?.last_name;
        },
      },
    ],

    [
      {
        Email: location?.state?.email,
      },
      {
        typographyStyle: { valueStyle: {} },
      },
    ],
    [
      {
        "Added On": location?.state?.date_joined,
      },
    ],
  ];

  return (
    <div style={{ minHeight: "95vh" }}>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          minHeight="calc(100vh - 120px)"
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <>
          <Grid container spacing={3} sx={{ height: "100%" }}>
            {/* START: Breadcrumb */}
            <Grid item xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            {/* END: Breadcrumb */}

            {/* START: Body Row */}
            <Grid item xs={12}>
              <UserDetail
                title="User Details"
                userEnabled={userEnabled}
                setUserEnabled={setUserEnabled}
                data={userData}
                actionTitle={userEnabled ? "Deactivate" : "Activate"}
                actionCallback={() => {
                  deactivateUser();
                }}
                actionStyles={
                  userEnabled
                    ? {
                        background: "#EAEAEA",
                        color: "#969696",
                        ":hover": {
                          background: "#d1d1d1",
                        },
                      }
                    : {
                        background: "rgba(71, 215, 162, 0.1);",
                        color: "#00B448",
                        ":hover": {
                          background: "rgba(34, 102, 77, 0.1);",
                        },
                      }
                }
                open={openDialog}
                setOpen={setOpenDialog}
                consent={
                  <Consent
                    open={openDialog}
                    setOpen={setOpenDialog}
                    title={
                      consentMessage[userEnabled ? "active" : "not_active"]
                        .title
                    }
                    description={
                      consentMessage[userEnabled ? "active" : "not_active"]
                        .description
                    }
                    acceptButtonTitle={userEnabled ? "Deactivate" : "Activate"}
                    rejectButtonTitle={"Close"}
                    handleSuccess={() => {
                      deactivateUser();
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default AuthHOC(UserDetails);
